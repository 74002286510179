import Link from "next/link";
import * as React from "react";

import { Button } from "@/components/buttons/Button";
import { DividerText } from "@/components/DividerText";
import { LoggedOutHeader } from "@/components/layout/Header/LoggedOutHeader";

import { EmailLogin } from "./EmailLogin";
import { PasswordLogin } from "./PasswordLogin";
import { SmsLogin } from "./SmsLogin";
import { LoginType } from "./types";
import { useLoginOptions } from "./useLoginOptions";
import { useRedirectToHomeIfLoggedIn } from "./useRedirectToHomeIfLoggedIn";

export const LoginPage = () => {
  const [isMagicLinkSent, setIsMagicLinkSent] = React.useState(false);

  const { loginOptions, defaultLoginOption } = useLoginOptions();
  const [loginType, toggleLoginType] = React.useState<LoginType>(
    defaultLoginOption.value
  );

  const otherLoginOptions = React.useMemo(() => {
    return loginOptions.filter((option) => {
      return option.value !== loginType;
    });
  }, [loginOptions, loginType]);

  useRedirectToHomeIfLoggedIn();

  const loginContentByType: { [key in LoginType]: React.ReactNode } = {
    email: (
      <EmailLogin
        isMagicLinkSent={isMagicLinkSent}
        onMagicLinkSent={() => {
          return setIsMagicLinkSent(true);
        }}
      />
    ),
    phone: (
      <SmsLogin
        isMagicLinkSent={isMagicLinkSent}
        onMagicLinkSent={() => {
          return setIsMagicLinkSent(true);
        }}
      />
    ),
    password: <PasswordLogin />,
  };

  return (
    <div className="min-h-screen bg-gray-50 antialiased">
      <LoggedOutHeader />
      <main className="layout mx-auto sm:pt-[70px]">
        <div className="flex flex-col items-center py-12 sm:px-6 lg:px-8">
          <Link
            href="/ios"
            className="mb-8 transition-transform hover:scale-105 sm:hidden"
            aria-label="Download on the App Store"
          >
            <img
              src="/images/app-store-badge.svg"
              alt="Download on the App Store"
              className="h-12"
            />
          </Link>

          <div className="mb-8 w-full max-w-md sm:hidden">
            <DividerText borderClass="border-gray-200">
              <span className="text-gray-500"></span>
            </DividerText>
          </div>

          <h1 className="mb-4 text-3xl text-primary-700">
            {isMagicLinkSent ? <>Magic Link Sent! 🪄</> : <>Login to UpSmith</>}
          </h1>
          <div className="w-full max-w-md rounded-xl bg-white p-8">
            {loginContentByType[loginType]}
            {!isMagicLinkSent && otherLoginOptions.length ? (
              <>
                <DividerText borderClass="border-gray-200">OR</DividerText>
                <div className="flex flex-col gap-2">
                  {otherLoginOptions.map((option) => {
                    return (
                      <Button
                        key={option.value}
                        fullWidth
                        size="lg"
                        rounded
                        variant="outline"
                        onClick={() => {
                          toggleLoginType(option.value);
                        }}
                      >
                        {option.label}
                      </Button>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </main>
    </div>
  );
};
