/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  Customer,
  CustomerConversationResponse,
  CustomerConversationsResponse,
  CustomerDetailResponse,
  ExportNearbyCustomersParams,
  GetCustomerConversationsParams,
  GetHeavenParams,
  GetNearbyCustomersParams,
  HTTPValidationError,
  HeavenResponse,
  NearbyCustomersListResponse,
  NearbyCustomersRequest,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Customer
 */
export const getCustomer = (
  tenantUuid: string,
  customerId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Customer>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/${customerId}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCustomerQueryKey = (
  tenantUuid: string,
  customerId: number
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/${customerId}`,
  ] as const;
};

export const getGetCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomerQueryKey(tenantUuid, customerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({
    signal,
  }) => getCustomer(tenantUuid, customerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && customerId),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomer>>
>;
export type GetCustomerQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Customer
 */

export function useGetCustomer<
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCustomerQueryOptions(
    tenantUuid,
    customerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Nearby Customers
 */
export const getNearbyCustomers = (
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NearbyCustomersListResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/nearby/${locationUuid}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetNearbyCustomersQueryKey = (
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/nearby/${locationUuid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetNearbyCustomersQueryOptions = <
  TData = Awaited<ReturnType<typeof getNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetNearbyCustomersQueryKey(tenantUuid, locationUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNearbyCustomers>>
  > = ({ signal }) =>
    getNearbyCustomers(
      tenantUuid,
      locationUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && locationUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getNearbyCustomers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNearbyCustomersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNearbyCustomers>>
>;
export type GetNearbyCustomersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Nearby Customers
 */

export function useGetNearbyCustomers<
  TData = Awaited<ReturnType<typeof getNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetNearbyCustomersQueryOptions(
    tenantUuid,
    locationUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Post Nearby Customers
 */
export const postNearbyCustomers = (
  tenantUuid: string,
  locationUuid: string,
  nearbyCustomersRequest: NearbyCustomersRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NearbyCustomersListResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/nearby/${locationUuid}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: nearbyCustomersRequest,
      signal,
    },
    options
  );
};

export const getPostNearbyCustomersMutationOptions = <
  TData = Awaited<ReturnType<typeof postNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["postNearbyCustomers"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postNearbyCustomers>>,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest }
  > = (props) => {
    const { tenantUuid, locationUuid, data } = props ?? {};

    return postNearbyCustomers(tenantUuid, locationUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
    TContext
  >;
};

export type PostNearbyCustomersMutationResult = NonNullable<
  Awaited<ReturnType<typeof postNearbyCustomers>>
>;
export type PostNearbyCustomersMutationBody = NearbyCustomersRequest;
export type PostNearbyCustomersMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Post Nearby Customers
 */
export const usePostNearbyCustomers = <
  TData = Awaited<ReturnType<typeof postNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
  TContext
> => {
  const mutationOptions = getPostNearbyCustomersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Export nearby customers to CSV
 */
export const exportNearbyCustomers = (
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/export_nearby/${locationUuid}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getExportNearbyCustomersQueryKey = (
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/export_nearby/${locationUuid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getExportNearbyCustomersQueryOptions = <
  TData = Awaited<ReturnType<typeof exportNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof exportNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getExportNearbyCustomersQueryKey(tenantUuid, locationUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof exportNearbyCustomers>>
  > = ({ signal }) =>
    exportNearbyCustomers(
      tenantUuid,
      locationUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && locationUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof exportNearbyCustomers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportNearbyCustomersQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportNearbyCustomers>>
>;
export type ExportNearbyCustomersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Export nearby customers to CSV
 */

export function useExportNearbyCustomers<
  TData = Awaited<ReturnType<typeof exportNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof exportNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getExportNearbyCustomersQueryOptions(
    tenantUuid,
    locationUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Export nearby customers to CSV
 */
export const postExportNearbyCustomers = (
  tenantUuid: string,
  locationUuid: string,
  nearbyCustomersRequest: NearbyCustomersRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/export_nearby/${locationUuid}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: nearbyCustomersRequest,
      signal,
    },
    options
  );
};

export const getPostExportNearbyCustomersMutationOptions = <
  TData = Awaited<ReturnType<typeof postExportNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["postExportNearbyCustomers"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postExportNearbyCustomers>>,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest }
  > = (props) => {
    const { tenantUuid, locationUuid, data } = props ?? {};

    return postExportNearbyCustomers(
      tenantUuid,
      locationUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
    TContext
  >;
};

export type PostExportNearbyCustomersMutationResult = NonNullable<
  Awaited<ReturnType<typeof postExportNearbyCustomers>>
>;
export type PostExportNearbyCustomersMutationBody = NearbyCustomersRequest;
export type PostExportNearbyCustomersMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Export nearby customers to CSV
 */
export const usePostExportNearbyCustomers = <
  TData = Awaited<ReturnType<typeof postExportNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; locationUuid: string; data: NearbyCustomersRequest },
  TContext
> => {
  const mutationOptions = getPostExportNearbyCustomersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get customer conversations
 */
export const getCustomerConversations = (
  tenantUuid: string,
  params?: GetCustomerConversationsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CustomerConversationsResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/conversation/list`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCustomerConversationsQueryKey = (
  tenantUuid: string,
  params?: GetCustomerConversationsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/conversation/list`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCustomerConversationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerConversations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCustomerConversationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerConversations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerConversationsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerConversations>>
  > = ({ signal }) =>
    getCustomerConversations(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerConversations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerConversationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerConversations>>
>;
export type GetCustomerConversationsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get customer conversations
 */

export function useGetCustomerConversations<
  TData = Awaited<ReturnType<typeof getCustomerConversations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCustomerConversationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerConversations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCustomerConversationsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get customer conversation
 */
export const getCustomerConversation = (
  tenantUuid: string,
  customerUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CustomerConversationResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/conversation/${customerUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCustomerConversationQueryKey = (
  tenantUuid: string,
  customerUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/conversation/${customerUuid}`,
  ] as const;
};

export const getGetCustomerConversationQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerConversation>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerConversation>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerConversationQueryKey(tenantUuid, customerUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerConversation>>
  > = ({ signal }) =>
    getCustomerConversation(tenantUuid, customerUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && customerUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerConversation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerConversationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerConversation>>
>;
export type GetCustomerConversationQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get customer conversation
 */

export function useGetCustomerConversation<
  TData = Awaited<ReturnType<typeof getCustomerConversation>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerConversation>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCustomerConversationQueryOptions(
    tenantUuid,
    customerUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Heaven
 */
export const getHeaven = (
  tenantUuid: string,
  params: GetHeavenParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<HeavenResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/heaven`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetHeavenQueryKey = (
  tenantUuid: string,
  params: GetHeavenParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/heaven`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetHeavenQueryOptions = <
  TData = Awaited<ReturnType<typeof getHeaven>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetHeavenParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getHeaven>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetHeavenQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHeaven>>> = ({
    signal,
  }) => getHeaven(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getHeaven>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetHeavenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHeaven>>
>;
export type GetHeavenQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Heaven
 */

export function useGetHeaven<
  TData = Awaited<ReturnType<typeof getHeaven>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetHeavenParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getHeaven>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetHeavenQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Customer Detail
 */
export const getCustomerDetail = (
  tenantUuid: string,
  customerUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CustomerDetailResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customers/${customerUuid}/details`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCustomerDetailQueryKey = (
  tenantUuid: string,
  customerUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customers/${customerUuid}/details`,
  ] as const;
};

export const getGetCustomerDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerDetail>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerDetailQueryKey(tenantUuid, customerUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerDetail>>
  > = ({ signal }) =>
    getCustomerDetail(tenantUuid, customerUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && customerUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerDetail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerDetail>>
>;
export type GetCustomerDetailQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Customer Detail
 */

export function useGetCustomerDetail<
  TData = Awaited<ReturnType<typeof getCustomerDetail>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCustomerDetailQueryOptions(
    tenantUuid,
    customerUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
