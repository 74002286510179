/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type SkuSoldBehaviorCreateSchemaItemSource =
  (typeof SkuSoldBehaviorCreateSchemaItemSource)[keyof typeof SkuSoldBehaviorCreateSchemaItemSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkuSoldBehaviorCreateSchemaItemSource = {
  invoice: "invoice",
  estimate: "estimate",
} as const;
