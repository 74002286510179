/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type SkuSoldQualifiedBehaviorOutboxSchemaSourceType =
  (typeof SkuSoldQualifiedBehaviorOutboxSchemaSourceType)[keyof typeof SkuSoldQualifiedBehaviorOutboxSchemaSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkuSoldQualifiedBehaviorOutboxSchemaSourceType = {
  invoice: "invoice",
  estimate: "estimate",
} as const;
